import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, mapOptions } from '../../utils';
import { FilterStore } from '../components/filter/store';
import { HeaderStore } from '../components/headerSearch/store';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import CatagoryNavStore from '../homePage/catagoryNav/store';
import { CATEGORY_MAP, CATEGORY_MAP_REVERSE } from '../homePage/constant';
import type { ICategory, IQuerycondition } from '../homePage/interface';
import type { ParentFtCategoryProps } from '../market/interface';
import { FILTER_ITEMS } from './constant';
import LiveGoodsSordStore from './sordPanel/store';
import type { TagsProps } from '../exhibitionHall/interface';
import type { ILiveGoodsFloorVo } from './interface';

const CHANNEL_TYPE_MAP = {
  newProduct: 2,
  peersChoose: 3,
};

const TITLE_MAP = { liveGoods: '直播专区 - 衫海精' };

interface IAllStyles {
  styleCode: string;
  name: string;
}

export default class Store {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    this.tabKey = search.get('tabKey');
    document.title = TITLE_MAP[this.tabKey];
    this.catagoryNavStore.queryMoreCategory();
    this.catagoryNavStore.getCategoryStyle();
    this.catagoryNavStore.queryBusinessDistrict();
    this.catagoryNavStore.queryCoreBusinessDistrict();
    this.getParentFtCategory();
    this.getStyle();
    this.initCitys();
    this.getTabData({
      page: 1,
      pageSize: 50,
    });
    this.getTagGroup();
  }

  public cityDistrictDict = [];

  @observable public loading = false;

  @observable public searchResult = false;

  @observable public pageParams: IQuerycondition = {
    page: 1,
    pageSize: 50,
    sidx: '',
    sord: '',
  };

  @observable public headerStore = new HeaderStore({ parent: this });

  @observable public paginationStore = new PaginationStore({ parent: this });

  @observable public catagoryNavStore = new CatagoryNavStore({ parent: this });

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public tabKey = '';

  @observable public liveGoodsList: ILiveGoodsFloorVo[] = []; // data

  @observable public currentParams: IObj = {};

  @observable public filterParamsTmp: IObj = {}; // 筛选条件

  @observable public category: ICategory[] = []; // 类目

  @observable public floorId = 0;

  @observable public sordPanelStore = new LiveGoodsSordStore({ parent: this });

  @observable public currentSord = '';

  // 获取tab数据
  @action private getTabData = (params?: IObj): void => {
    this.loading = true;
    this.currentParams = {
      ...this.pageParams,
      ...this.filterParamsTmp,
      ...params,
      channelType: CHANNEL_TYPE_MAP[this.tabKey] || 2,
      parentFtCategoryId: CATEGORY_MAP_REVERSE[this.headerStore.clothes],
      ftCategoryIds: this.filterParamsTmp?.ftCategoryId ? [this.filterParamsTmp?.ftCategoryId] : undefined,
      applicableAge: this.filterParamsTmp?.applicableAge ? [this.filterParamsTmp.applicableAge] : [],
      styleCodes: this.filterParamsTmp?.style ? [this.filterParamsTmp.style] : [],
    };
    const currentParams = { ...this.currentParams };
    delete currentParams.style;

    // 参数只有parentFtCategoryId没有ftCategoryId时，要把parentFtCategoryId替换成ftCategoryId，因为目前借口只会识别ftCategoryId
    if (currentParams.parentFtCategoryId && !currentParams.ftCategoryId) {
      currentParams.ftCategoryId = currentParams.parentFtCategoryId;
    }
    request<BaseData<ILiveGoodsFloorVo[]>>({
      url: api.getLiveGoodsAreaPage,
      method: 'post',
      data: { ...currentParams },
    })
      .then((res) => {
        this.loading = false;
        this.liveGoodsList = res.data || [];
      })
      .catch(() => {
        this.loading = false;
        this.liveGoodsList = [];
      });
  };

  @action public onPageChange = (page: number, pageSize: number): void => {
    this.getTabData({
      page,
      pageSize,
    });
  };

  @action public onShowSizeChange = (page = 1, pageSize: number): void => {
    this.getTabData({
      page,
      pageSize,
    });
  };

  public filterParams = new FilterStore({
    filterItems: FILTER_ITEMS(this),
    handleSearch: () => {
      this.paginationStore.resetPage();
      this.filterParamsTmp = this.filterParams.params;
      this.getTabData({
        page: 1,
        pageSize: 50,
      });
    },
  });

  // 处理前端二三级类目
  private dealFtCategory = (categoryList: ParentFtCategoryProps[]) => {
    return categoryList?.map((category) => {
      if (!category.child || category.child.length === 0) {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: [],
        };
      } else {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: this.dealFtCategory(category.child),
        };
      }
    });
  };

  // 获取全部类目
  @action
  public getParentFtCategory = async(): Promise<void> => {
    const req = await request<BaseData<ParentFtCategoryProps[]>>({ url: api.queryMoreCategory });
    this.category = req.data;
    this.getFtCategory(this.headerStore.clothes);
  };

  // 获取细分类别
  @action
  public getFtCategory = (categoryId: number): void => {
    if (categoryId) {
      this.filterParams.updateFilterParams({ ftCategoryId: undefined });
      this.currentParams.ftCategoryIds = [];
      this.filterParamsTmp.ftCategoryId = undefined;
    }
    const currentCategory = this.category?.find((v) => CATEGORY_MAP[v.id] === categoryId);
    const ftCategoryId = this.dealFtCategory(currentCategory?.child);
    this.filterParams.updateFilterItems({ ftCategoryId });
  };

  // 初始化城市
  public initCitys = () => {
    // 获取城市
    request<BaseData<[]>>({ url: api.queryBusinessDistrict })
      .then((res) => {
        this.cityDistrictDict = res.data;
        const cityOptions = mapOptions(res.data, 'cityId', 'cityName');
        this.filterParams.updateFilterItems({ cityId: cityOptions });
      });
  };

  public getDistrictOptions = (cityId) => {
    // 获取商圈
    this.filterParams.updateFilterParams({ businessDistrictId: '' });

    const selectedItem = this.cityDistrictDict.find((item) => item.cityId === cityId);
    const districtOptions = mapOptions(selectedItem.businessDistrictList, 'id', 'name');
    this.filterParams.updateFilterItems({ businessDistrictId: districtOptions });
  };

  // 获取风格
  @action
  public getStyle = async(): Promise<void> => {
    const req = await request<BaseData<IAllStyles[]>>({ url: `${api.getAllStyles}` });
    this.filterParams.updateFilterItems({
      style: req.data.map((item) => ({
        label: item.name,
        value: item.styleCode,
      })),
    });
  };

  @action
  public getTagGroup = async(): Promise<void> => {
    const req = await request<BaseData<TagsProps[]>>({
      url: `${api.getTagGroup}?type=3`,
      method: 'get',
    });
    this.filterParams.updateFilterItems({
      applicableAge: req.data.filter((item) => item.tagGroupId === 100019)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
    });
  };

  // 品类和风格馆跳搜索页
  @action public gotoSearchResultByConditon = (childId: number | string, parentId?: number, type?: string): void => {
    let url = `/egenie-ts-vogue/searchResult/index?searchName=${this.headerStore.searchName}`;
    if (type === 'business') {
      url += `&businessDistrictId=${childId}`;
    } else {
      if (parentId) {
        url += `&parentCategoryId=${parentId}&ftCategoryIds=${childId}`;
      } else {
        url += `&clothesStyle=${childId}`;
      }
    }
    this.catagoryNavStore.toogleAllCategory(false, null, '', []);
    this.headerStore.gotoPage(url);
  };

  @action public onQuery = (floorId: number) => {
    this.floorId = floorId;
    this.getTabData({
      page: 1,
      pageSize: 50,
    });
  };

  @action public handleQuery = (params: any) => {
    this.currentSord = params?.sidx;
    this.getTabData(params);
  };

  // 跳转页面
  @action public gotoPage = (url): void => {
    this.headerStore.gotoPage(url);
  };
}
