import type { FilterItemProps } from '../components/filter/interface';

export const FILTER_ITEMS = (store): FilterItemProps[] => {
  return [
    {
      type: 'treeSelect',
      label: '',
      field: 'ftCategoryId',
      showSearch: true,
      allowClear: true,
      placeholder: '全部品类',
    },
    {
      type: 'select',
      showSearch: true,
      label: '',
      field: 'style',
      allowClear: true,
      placeholder: '全部风格',
    },
    {
      type: 'select',
      showSearch: true,
      label: '',
      field: 'applicableAge',
      allowClear: true,
      placeholder: '适用年龄',
    },
    {
      type: 'select',
      showSearch: true,
      label: '',
      field: 'cityId',
      allowClear: true,
      placeholder: '全部城市',
      onChangeCallback: (value) => {
        store.getDistrictOptions(value);
      },
    },
  ];
};
