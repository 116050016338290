// 直播专区
import { observer } from 'mobx-react';
import React from 'react';
import EmptyStatus from '../components/empty';
import { Filter } from '../components/filter';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { HeaderSearch } from '../components/headerSearch';
import { Navigation } from '../components/navigation';
import { RightSider } from '../components/rightSider';
import CatagoryNav from '../homePage/catagoryNav';
import styles from './index.less';
import Store from './store';
import { LiveGoodsLeftSlider } from './leftSider';
import { SordPanel } from './sordPanel';
import { CommonCardWrap } from '../homePage/commonCardWrap';

const store = new Store();

@observer
export default class LiveGoods extends React.Component {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const {
      loading,
      liveGoodsList,
      headerStore,
      headerStore: { gotoPage },
      filterParams,
      rightSiderStore,
      sordPanelStore,
      currentSord,
    } = store;
    return (
      <div
        className={styles.contentWrap}
        id="goodsListCommon"
      >
        <LiveGoodsLeftSlider/>
        <Navigation/>
        <HeaderSearch
          showTabs
          store={headerStore}

        />
        <div
          className={styles.recentlyNewWrap}
          style={{ height: (liveGoodsList.length === 0) ? 'calc(100% - 410px)' : 'auto' }}
        >
          <CatagoryNav
            catagoryNavStore={store.catagoryNavStore}
            store={store}
          />

          <div className={styles.marketByStyle}>
            <Filter store={filterParams}/>
            <SordPanel
              currentSord={currentSord}
              store={sordPanelStore}
            />
          </div>
          {liveGoodsList && liveGoodsList.length > 0 && (
            liveGoodsList.map((e, index) => {
              return (
                <CommonCardWrap
                  dataList={e.floorGoodsVos}
                  id={index === 0 ? 'root' : e.floorId}
                  key={e.floorId}
                  loading={loading}
                  showMore={false}

                  // @ts-ignore
                  store={store}
                  style={index === 0 ? { padding: '0 0 0 20px' } : {}}
                  title={e.floorName}
                  type="liveGoods"
                />
              );
            })
          )}
          {
            (!loading && liveGoodsList.length === 0) && (
              <div className={styles.contentWrapEmpty}>
                <EmptyStatus/>
              </div>
            )
          }
        </div>
        <RightSider store={rightSiderStore}/>
        <FooterLogo/>
        <Footer/>
      </div>

    );
  }
}

