import { Anchor } from 'antd';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './index.less';
import { LiveGoodsLeftSliderStore } from './store';

const { Link } = Anchor;

export const LiveGoodsLeftSlider = observer(() => {
  const [store] = useState(() => new LiveGoodsLeftSliderStore());

  const [
    showSlider,
    setShowSlider,
  ] = useState(true);

  const [
    leftDistance,
    setLeftDistance,
  ] = useState(0);

  const [
    offsetTop,
    setOffSetTop,
  ] = useState(223);// 滚动锚点偏移距离
  const scrollHeightRef = useRef<number>();// 记录上次渲染时整个页面高度

  //  计算导航条距左边距离
  const calcDistance = function() {
    const windowWidth = window.innerWidth;

    setShowSlider(windowWidth > 1360);
    if (windowWidth > 1360) {
      const singleDistance = (window.innerWidth - 1200) / 2;
      setLeftDistance(singleDistance - 80);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', calcDistance);
    return () => {
      window.removeEventListener('resize', calcDistance);
    };
  }, []);
  useLayoutEffect(() => {
    calcDistance();
  }, []);

  if (document.documentElement.scrollHeight - scrollHeightRef.current === 142 && offsetTop !== 223) {
    setOffSetTop(223);
  }

  if (scrollHeightRef.current - document.documentElement.scrollHeight === 142 && offsetTop !== 76) {
    setOffSetTop(76);
  }

  scrollHeightRef.current = document.documentElement.scrollHeight;// 记录上一次的高度
  const { floorList } = store;
  return (
    <Anchor
      className={styles.anchorWrap}
      offsetTop={offsetTop}
      style={{
        left: leftDistance,
        display: showSlider ? 'block' : 'none',
      }}
      targetOffset={offsetTop}
    >
      {
        floorList?.map((item, index) => (
          <Fragment key={index === 0 ? 'root' : item.floorId}>
            <Link
              href={`#${index === 0 ? 'root' : item.floorId}`}
              title={item.floorName}
            />
            {index !== floorList.length - 1 ? <div className={styles.divider}/> : null}
          </Fragment>
        ))
      }
    </Anchor>
  );
});
