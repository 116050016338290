import { action, observable } from 'mobx';
import { api } from '../../../utils';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';

export class LiveGoodsLeftSliderStore {
  constructor() {
    this.init();
  }

  @observable public parent;

  @observable public floorList = [];

  @action public init = async(): Promise<void> => {
    const results = await request<BaseData<Array<{ floorId: string; floorName: string; sort: number; }>>>({
      url: api.getLiveGoodsFloorList,
      method: 'GET',
    });
    this.floorList = results.data;
  };
}
