/**
 * 搜索结果页排序
 */
import { InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { INPUTNUMBER_PROPS, PRICE_IMG_MAP, SORT_LIST } from './constant';
import styles from './index.less';
import type Store from './store';

// 搜索结果页-综合排序面板
export const SordPanel = observer((props: { store: Store; currentSord: string; }) => {
  const {
    store: {
      priceType,
      gotoSord,
      showBtn,
      btnVisible,
      minProductPrice,
      maxProductPrice,
      confirm,
      cancel,
      changeNum,
      changeCheckbox,
      changeCurrentType,
      currentType,
    },
    currentSord,
  } = props;
  return (
    <div className={styles.searchResultSordPanelWrap}>
      {
        SORT_LIST.map((item) => {
          const {
            label,
            value,
          } = item;
          return (
            <div
              className={`${styles.sordItemWrap} ${currentSord === value ? styles.itemSelect : ''}`}
              key={value}
              onClick={() => {
                gotoSord(value, priceType);
              }}
            >
              {label}
              {value === 'productPrice' && (
                <img
                  alt=""
                  className={styles.priceImg}
                  src={PRICE_IMG_MAP[priceType]}
                />
              )}
            </div>
          );
        })
      }

      <div className={styles.priceSectionWrap}>
        <span className={styles.priceSectionContainer}>
          <span
            className={currentType === 0 ? `${styles.priceSectionBtnSelected}` : `${styles.priceSectionBtn}`}
            onClick={() => changeCurrentType(0)}
          >
            直播价
          </span>
          <span
            className={currentType === 1 ? `${styles.priceSectionBtnSelected}` : `${styles.priceSectionBtn}`}
            onClick={() => changeCurrentType(1)}
          >
            佣金
          </span>
        </span>
        <InputNumber
          {...INPUTNUMBER_PROPS}
          onChange={(value) => {
            changeNum(value, 'minProductPrice');
          }}
          onFocus={showBtn}
          placeholder={currentType === 0 ? '最低价' : '最低比例'}
          value={minProductPrice}
        />
        {' '}
        -
        {' '}
        <InputNumber
          {...INPUTNUMBER_PROPS}
          onChange={(value) => {
            changeNum(value, 'maxProductPrice');
          }}
          onFocus={showBtn}
          placeholder={currentType === 0 ? '最高价' : '最高比例'}
          value={maxProductPrice}
        />
        {
          btnVisible && (
            <>
              <span
                className={`${styles.btnCommon} ${styles.btnConfirm}`}
                onClick={confirm}
              >
                确定
              </span>
              <span
                className={styles.btnCommon}
                onClick={cancel}
              >
                取消
              </span>
            </>
          )
        }
      </div>
    </div>
  );
});
