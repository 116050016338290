import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { action, observable } from 'mobx';
import { PARAMS, PRICE_SORD } from './constant';

interface IQuerycondition {
  page: number;
  pageSize: number;
  sidx: string;
  sord: string;
}

export default class LiveGoodsSordStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public priceType = '';

  @observable public btnVisible = false;

  @observable public minProductPrice: number = null;

  @observable public maxProductPrice: number = null;

  @observable public currentType = 0;

  // 排序价格排序，需要sord：desc和asc
  @observable public gotoSord = (sidx: string, sord: string): void => {
    this.closeBtnPanel();
    let params: Partial<IQuerycondition> = { ...PARAMS };
    if (sidx !== 'productPrice') {
      this.priceType = '';
      params = {
        ...params,
        sord: '',
        sidx,
      };
    } else {
      this.priceType = PRICE_SORD[sord];
      params = {
        ...params,
        sord: this.priceType,
        sidx: this.priceType !== '' ? sidx : '',
      };
    }
    this.parent?.paginationStore?.resetPage();
    this.parent?.handleQuery({ ...params });
  };

  @action public showBtn = (): void => {
    this.btnVisible = true;
  };

  @action public changeNum = (value: string | number, type: string): void => {
    this[type] = value;
  };

  // 价格排序确认，其他搜索置空
  @action public confirm = (): void => {
    this.priceType = '';
    this.btnVisible = false;
    this.parent?.paginationStore?.resetPage();
    let params: any = {
      ...PARAMS,
      sidx: '',
      sord: '',

    };
    if (this.currentType === 0) {
      params = {
        ...params,
        minLiveGoodsPrice: (this.minProductPrice || this.minProductPrice === 0) ? this.minProductPrice : undefined,
        maxLiveGoodsPrice: (this.maxProductPrice || this.maxProductPrice === 0) ? this.maxProductPrice : undefined,
      };
    } else if (this.currentType === 1) {
      params = {
        ...params,
        minBrokerageRate: (this.minProductPrice || this.minProductPrice === 0) ? this.minProductPrice : undefined,
        maxBrokerageRate: (this.maxProductPrice || this.maxProductPrice === 0) ? this.maxProductPrice : undefined,
      };
    }
    this.parent.handleQuery(params);
  };

  @action public cancel = (): void => {
    this.closeBtnPanel();
    this.parent.handleQuery({
      ...PARAMS,
      sidx: '',
      sord: '',
      minProductPrice: undefined,
      maxProductPrice: undefined,
    });
  };

  @action public closeBtnPanel = (): void => {
    this.btnVisible = false;
    this.minProductPrice = null;
    this.maxProductPrice = null;
  };

  // checkBox筛选
  @action public changeCheckbox = (e: CheckboxChangeEvent, type: string): void => {
    this.parent.handleQuery({
      ...PARAMS,
      minProductPrice: (this.minProductPrice || this.minProductPrice === 0) ? this.minProductPrice : undefined,
      maxProductPrice: (this.maxProductPrice || this.maxProductPrice === 0) ? this.maxProductPrice : undefined,
    }, {
      ...this.parent.searchResultFilter,
      [type]: e.target.checked,
    });
  };

  @action public changeCurrentType = (type: number) => {
    this.currentType = type;
  };
}
