// 首页：新品，同行都在选，猜你喜欢
import { Col, Row, Spin } from 'antd';
import type { IObj } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { ProductCard } from '../../components';
import EmptyStatus from '../../components/empty';
import { MoreInfo } from '../dynamicInfo';
import type { Store } from '../store';
import styles from './index.less';
import { LiveGoodsCard } from '../../components/liveGoodsCard';

export const CommonCardWrap = observer((props: {
  store: Store;
  id: string;
  type: string;
  title: string;
  dataList: IObj[];
  jumpToMore?: () => void;
  recommendedLoading?: boolean;
  showMore?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
}) => {
  const {
    gotoPage,
    rightSiderStore,
  } = props.store;
  const {
    id,
    loading,
    showMore,
    title,
    dataList,
    type,
    recommendedLoading,
    jumpToMore,
    style,
  } = props;
  return (
    <div
      className={styles.cardInfo}
      id={id}
      style={style}
    >
      <Spin spinning={loading}>
        <div
          className={styles.commonCardWrapTitle}
        >
          <div className={styles.title}>
            {title}
          </div>
          {showMore && (
            <MoreInfo
              gotoPage={jumpToMore}
              title="更多"
            />
          )}
        </div>
        <GoodsInfoListWrap
          businessType={title}
          dataList={dataList}
          gotoPage={gotoPage}
          loading={loading}
          recommendedLoading={recommendedLoading}
          rightSiderStore={rightSiderStore}
          type={type}
        />
      </Spin>
    </div>
  );
});

export const GoodsInfoListWrap = observer((props) => {
  const {
    dataList,
    gotoPage,
    type,
    recommendedLoading,
    loading,
    businessType,
    rightSiderStore,
  } = props;
  return (
    <div
      className={styles.commonCardWrap}
      style={{ alignItems: dataList?.length > 0 ? 'flex-start' : 'center' }}
    >
      <Row gutter={[
        12,
        12,
      ]}
      >
        {
          dataList?.map((item, index) => {
            const { goodsId } = item;
            return (
              <Col key={goodsId}>
                {type === 'liveGoods' ? (
                  <LiveGoodsCard
                    onClick={() => {
                      gotoPage(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                    }}
                    trackingInfo={{
                      name: '直播专区',
                      businessType,
                    }}
                    {...item}
                    callback={() => {
                      rightSiderStore.getMyStyleWaitNum();
                    }}
                  />
                ) : (
                  <ProductCard
                    onClick={() => {
                      gotoPage(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                    }}
                    trackingInfo={{
                      name: '款式广场',
                      businessType,
                    }}
                    {...item}
                    callback={() => {
                      rightSiderStore.getMyStyleWaitNum();
                    }}
                  />
                )}
              </Col>
            );
          })
        }
      </Row>
      {
        dataList?.length === 0 && !loading && (
          <EmptyStatus/>
        )
      }
      {type === 'recommended' && recommendedLoading && (
        <div className={styles.recommondLoadingWrap}>
          <Spin/>
        </div>
      )}
    </div>
  );
});
