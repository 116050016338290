import { message } from 'antd';
import type { BaseData, User } from 'egenie-common';
import { request, toFixed } from 'egenie-common';
import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import noPic from '../../../assets/images/noPic.png';
import { api, checkLoginStatus, isDistributor, LoginModal, PUBLIC_IMAGE_URL, replacePath, SellingPointTag, useCheckLogin } from '../../../utils';
import { LaunchModal, LaunchStore } from '../index';
import styles from './index.less';
import type { ILiveGoodsVo } from '../../liveGoods/interface';

/**
 * @name 商品卡片
 * @param style 行内样式
 * @param className 类名
 * @param mainPic 主图
 * @param goodsId 商品id
 * @param goodsName 商品名称
 * @param marketLabelName 市场名称
 * @param videoFlag 视频标记，0无视频、1有视频
 * @param isOpen 私密款标记，1公开、2私密
 * @param picGroupNum 组图数量
 * @param returnable 是否可退0: 不可退、1可退部分、2无忧退货
 * @param returnRate 可退比例
 * @param wholePrice 进货价/成本价
 * @param discountPrice 折扣价/专享价
 * @param activityPrice 活动价
 * @param dynamicId 动态id
 * @param sellingPointTag 卖点标签
 * @param doesAddStyle 是否加入我的款式
 * @param virtualSaleNum 销量(假数据)
 * @param virtualSelectNum 铺货数量(假数据)
 * @callback onClick 点击的回调
 * @returns
 */
export const LiveGoodsCard: React.FC<Partial<{
  style: CSSProperties;
  className: string;
  mainPic: string;
  goodsName: string;
  goodsId: number;
  marketLabelName: string;
  isOpen: 1 | 2;
  returnable: 0 | 1 | 2;
  returnRate: number;
  wholePrice: number;
  discountPrice: number;
  activityPrice: number;
  videoFlag: 0 | 1;
  picGroupNum: number;
  dynamicId: string;
  onClick: () => void;
  trackingInfo: object;
  overflow?: boolean;
  sellingPointTag: string;
  doesAddStyle: boolean;
  virtualSaleNum: number;
  virtualSelectNum: number;
  callback?: () => void;
  sellerOuterNo: string;// 货号
  liveGoodsVo: ILiveGoodsVo;
}>> = (props) => {
  const {
    className,
    style,
    mainPic,
    goodsName,
    marketLabelName,
    isOpen,
    videoFlag,
    returnable,
    returnRate,
    wholePrice,
    goodsId,
    onClick,
    picGroupNum,
    dynamicId,
    trackingInfo,
    overflow,
    sellingPointTag,
    doesAddStyle,
    callback,
    sellerOuterNo,
    liveGoodsVo,
    virtualSaleNum,
    virtualSelectNum,
  } = props;
  const {
    checkLoginFn,
    visible,
    setVisible,
  } = useCheckLogin();

  const launchStore = new LaunchStore({ parent: undefined });
  const [
    hover,
    setHover,
  ] = useState(false);

  const [
    loading,
    setLoading,
  ] = useState(false);

  const [
    addStyle,
    setAddStyle,
  ] = useState(false);

  useEffect(() => {
    setAddStyle(doesAddStyle);
  }, [doesAddStyle]);

  const onClickCard = () => {
    if (onClick) {
      onClick();
    } else {
      window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}&dynamicId=${dynamicId || ''}`));
    }
  };

  // 一键铺货
  const openLaunchModal = async(e): Promise<void> => {
    e.stopPropagation();
    if (isDistributor()) {
      checkLoginFn(() => {
        launchStore?.openModal(goodsId, dynamicId, 'front');
      });
    } else {
      const loginStatus = await checkLoginStatus();
      if (!loginStatus) {
        window.location.assign('/egenie-ts-vogue/login');
        return;
      }
      launchStore?.openModal(goodsId, dynamicId, 'front');
    }
  };

  // 加入我的款式
  const addMyStyle = async(e): Promise<void> => {
    e.stopPropagation();
    if (isDistributor()) {
      checkLoginFn(() => {
        dealAddStyle();
      });
    } else {
      const loginStatus = await checkLoginStatus();
      if (!loginStatus) {
        window.location.assign('/egenie-ts-vogue/login');
      }
      dealAddStyle();
    }
  };
  const dealAddStyle = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    request<BaseData<string>>({
      url: '/api/mall/rest/goods/addStyle',
      method: 'GET',
      params: { goodsId },
    })
      .then((res) => {
        message.success('加入成功');
        setAddStyle(true);
        callback();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 跳转到我的款式
  const gotoMyStyle = async(e): Promise<void> => {
    e.stopPropagation();
    const userInfo: User = await request({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      window.open(`${api.switchToErpHome}&jumpTo=myStyle`); // erp账户跳转到erp首页
    } else {
      window.open('/egenie-erp-home/shjHome?jumpTo=myStyle');
    }
  };

  return (
    <div
      className={`${styles.card} ${className ? className : ''}`}
      data-id={JSON.stringify({
        ...trackingInfo,
        goodsId,
        jump: '/egenie-ts-vogue/goodsDetail/index',
      })}
      onClick={onClickCard}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}

      style={{ ...style }}
    >
      <div
        className={styles.topContainer}
      >
        <LazyLoad
          height={200}
          overflow={overflow}
          style={{ textAlign: 'center' }}
        >
          <img
            className={styles.mainPicture}
            src={mainPic || noPic}
          />
        </LazyLoad>
        <div className={styles.topLabels}>
          {isOpen === 2 && (
            <div className={styles.visible}>
              密
            </div>
          )}
          {Boolean(videoFlag) && (
            <div className={styles.video}>
              <img
                className={styles.videoIcon}
                src={`${PUBLIC_IMAGE_URL}video.png`}
              />
              <span>
                视频
              </span>
            </div>
          )}
        </div>
        {sellingPointTag && (
          <div className={styles.specialTag}>
            {sellingPointTag}
          </div>
        )}
        {hover && (
          <div className={styles.operationBtns}>
            <div
              className={styles.btn}
              onClick={openLaunchModal}
            >
              一键铺货
            </div>
            <div className={styles.splitIcon}/>
            <div
              className={styles.btn}
              onClick={addStyle ? gotoMyStyle : addMyStyle}
            >
              {addStyle && '已'}
              加入我的款式
            </div>
          </div>
        )}
        <div className={styles.bottomLabels}>
          {!hover && picGroupNum && picGroupNum > 1 && (
            <div className={styles.picGroup}>
              {picGroupNum}
              <span>
                套图
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.bottomContainer}
        onClick={onClickCard}
      >
        {hover
          ? (
            <div
              className={styles.saleNum}
            >
              <div>
                <span className={styles.saleNumLabel}>
                  销量：
                </span>
                {virtualSaleNum > 100000 ? '99999+' : virtualSaleNum}
                件
              </div>
              <div>
                <span className={styles.saleNumLabel}>
                  铺货次数：
                </span>
                {virtualSelectNum}
                次
              </div>
            </div>
          )
          : (
            <>
              <div className={styles.goodsName}>
                {goodsName}
              </div>
              <span className={styles.sellerOuterNoRow}>
                <div className={styles.sellerOuterNo}>
                  货号：
                  {sellerOuterNo}
                </div>
                <div className={`${styles.sellerOuterNo} ${styles.ml16}`}>
                  库存：
                  {liveGoodsVo.stockNum > 9999 ? '9999+' : liveGoodsVo.stockNum}
                  件
                </div>
              </span>
              <div className={styles.tagContainer}>
                {liveGoodsVo.sellingPointLabelCode > 0 && (
                  <Tag
                    code={liveGoodsVo.sellingPointLabelCode}
                    content={liveGoodsVo.sellingPointLabelName}
                  />
                )}
                {liveGoodsVo.fastReflexes > 0 && (
                  <Tag
                    content={`${liveGoodsVo.fastReflexes}天快返`}
                  />
                )}
                {returnable === 0 && (
                  <Tag
                    content="不支持退货"
                  />
                )}
                {returnRate > 0 && (
                  <Tag
                    content={`可退${returnRate}%`}
                  />
                )}
              </div>
              <div className={styles.price}>
                <PriceLine
                  content={wholePrice}
                  label="进货价"
                />
                <PriceLine
                  content={liveGoodsVo.goodsLivePrice}
                  label="建议直播价"
                  showLine={liveGoodsVo.goodsLivePrice === 0}
                />
                <PriceLine
                  content={liveGoodsVo.brokerageRate}
                  isPrice={false}
                  label="佣金"
                  showLine={liveGoodsVo.brokerageRate === 0}
                />
              </div>
            </>
          )}
      </div>
      <LaunchModal store={launchStore}/>
      <LoginModal
        setVisible={setVisible}
        visible={visible}
      />
    </div>
  );
};

/**
 * @name 标签
 * @returns
 */
export const Tag: React.FC<{
  content: string;
  code?: number;
}> = (props) => {
  const {
    content,
    code,
  } = props;
  return (
    <div style={{
      borderRadius: '2px',
      backgroundColor: code > 0 ? SellingPointTag[code]?.backgroundColor : '#F2F2F2',
      color: code > 0 ? SellingPointTag[code]?.color : '#2B2E3E',
      marginRight: '2px',
      padding: '2px 4px',
    }}
    >
      {content}
    </div>
  );
};

/**
 * @name 价格展示
 * @param content 价格或分佣比例
 * @param isPrice 是否是价格，如果是比例则展示%符号
 * @param label 标题
 * @returns
 */
export const PriceLine: React.FC<Partial<{
  content: number | string;
  isPrice: boolean;
  label: string;
  showLine: boolean;
}>> = (props) => {
  const {
    content,
    isPrice = true,
    label = '进货价',
    showLine = false,
  } = props;

  // 处理价格
  const handlePrice = (content: number | string, isPrice: boolean): { intPart: string; decimalPart: string; } => {
    if (!isPrice) {
      return {
        intPart: content.toString(),
        decimalPart: '%',
      };
    }
    const [
      intPart,
      decimalPart,
    ] = toFixed(content, 2)
      .split('.');
    return {
      intPart,
      decimalPart,
    };
  };
  const {
    intPart,
    decimalPart,
  } = handlePrice(content, isPrice);

  // 价格展示部分
  const pricePart = (
    <div className={styles.showPrice}>
      {isPrice && (
        <span className={styles.priceIcon}>
          ¥
        </span>
      )}
      <span className={styles.intPart}>
        {intPart}
        {isPrice ? '.' : ''}
      </span>
      <span className={styles.decimalPart}>
        {decimalPart}
      </span>
    </div>
  );

  // 价格标签
  const LineThrough: React.FC<{ name: string; price: number | string; showLine: boolean; }> = (props) => {
    const {
      name,
      price,
    } = props;
    return (
      <div className={styles.ml8}>
        {showLine ? (
          <div className={styles.showPrice}>
            <span className={styles.intPart}>
              --
            </span>
          </div>
        ) : pricePart}
        <div className={styles.priceLabel}>
          {name}
        </div>
      </div>
    );
  };
  return (
    <LineThrough
      name={label}
      price={content}
      showLine
    />
  );
};

